import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { call } from "services/axiosService/core";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
// import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";

export default function CheckoutForm({
  childDetails,
  handleClosePaymentModal,
  amountToProcess,
  setSessionExpired,
  couponUsed,
  subscriptionType,
}) {
  // const [dispatch] = useMaterialUIController();

  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const { t } = useTranslation();
  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    // stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //   switch (paymentIntent.status) {
    //     case "succeeded":
    //       setMessage("Payment succeeded!");
    //       break;
    //     case "processing":
    //       setMessage("Your payment is processing.");
    //       break;
    //     case "requires_payment_method":
    //       setMessage("Your payment was not successful, please try again.");
    //       break;
    //     default:
    //       setMessage("Something went wrong.");
    //       break;
    //   }
    // });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/profile`,
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      setMessage(error.message);
      updatePaymentStatus("fail");
    } else if (paymentIntent && paymentIntent.status == "succeeded") {
      setMessage("Payment Suceess");
      updatePaymentStatus("success");
    } else if (paymentIntent && paymentIntent.status == "canceled") {
      updatePaymentStatus("fail");
      setMessage("Payment canceled");
    } else {
      updatePaymentStatus("fail");
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  const LinkAuthenticationElementOptions = {
    defaultValues: { email: profile.email },
  };
  const AddressElementOptions = {
    mode: "billing",
  };

  const updatePaymentStatus = (status) => {
    if (status == "success") {
      // Update the coupon if used - Descrease coupon remaing usage count and add user email adress to coupon uses object
      // Upate coupon if coupon applied while payment
      if (couponUsed) {
        localStorage.removeItem("referral");
        call(`payments/coupon/${couponUsed}`, "PUT", { body: { email: profile.email } })
          .then((response) => {
            console.log("Coupon used");
          })
          .catch(function (error) {
            Sentry.captureException(error);
            console.log(error);
          });
      }
      call(`users/${profile.id}/emails`, "POST", {
        body: {
          email_type: "payment_success_email",
          recipient_id: profile.id,
          amount_paid: amountToProcess,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            console.log("Email Success");
          } else if (response.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch(function (error) {
          Sentry.captureException(error);
          console.log(error);
        });

      if (childDetails.id == profile.id) {
        let LearnerDetails = childDetails;
        LearnerDetails.payment_status = "success";
        LearnerDetails.paid_at = new Date();
        if (couponUsed) {
          LearnerDetails.referral_code = couponUsed;
          const source = LearnerDetails.source;
          LearnerDetails.source = source
            ? source
            : couponUsed && couponUsed.includes("_")
            ? couponUsed.split("_")[0] + "_code"
            : null;
        }
        LearnerDetails.subscription_type = subscriptionType;
        let true_users = profile.true_mindsets_users ? profile.true_mindsets_users : [];
        if (subscriptionType != "maxu_yearly") {
          if (!true_users.includes(profile.id)) {
            true_users.push(profile.id);
          }
        }
        LearnerDetails.true_mindsets_users = true_users;
        call(`users/${LearnerDetails.id}`, "PUT", {
          body: LearnerDetails,
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => {
            if (response.status == 200) {
              handleClosePaymentModal("success");
            } else if (response.status == 440) {
              setSessionExpired(true);
            }
          })
          .catch(function (error) {
            Sentry.captureException(error);
            console.log(error);
          });
      } else {
        const AdultDetails = profile;
        const LearnerDetails = childDetails;
        let true_users = profile.true_mindsets_users ? profile.true_mindsets_users : [];
        let true_subs = ["true_mindsets_yearly", "true_mindsets_monthly"]
        if (true_subs.includes(subscriptionType)) {
          if (!true_users.includes(LearnerDetails.id)) {
            true_users.push(LearnerDetails.id);
          }
        }
        AdultDetails.true_mindsets_users = true_users;
        LearnerDetails.payment_status = "success";
        LearnerDetails.paid_at = new Date();
        if (couponUsed) {
          LearnerDetails.referral_code = couponUsed;
          const source = LearnerDetails.source;
          LearnerDetails.source = source
            ? source
            : couponUsed && couponUsed.includes("_")
            ? couponUsed.split("_")[0] + "_code"
            : null;
        }
        LearnerDetails.subscription_type = subscriptionType;

        call(`users/${profile.id}`, "PUT", {
          body: AdultDetails,
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => {
            if (response.status == 200) {
              console.log("profile updated");
            } else if (response.status == 440) {
              setSessionExpired(true);
            }
          })
          .catch(function (error) {
            Sentry.captureException(error);
            console.log(error);
          });
        call(`users/${LearnerDetails.id}`, "PUT", {
          body: LearnerDetails,
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => {
            if (response.status == 200) {
              handleClosePaymentModal("success");
            } else if (response.status == 440) {
              setSessionExpired(true);
            }
          })
          .catch(function (error) {
            Sentry.captureException(error);
            console.log(error);
          });
      }
    } else {
      const LearnerDetails = childDetails;
      LearnerDetails.payment_status = status;
      LearnerDetails.subscription_type = subscriptionType;
      call(`users/${LearnerDetails.id}`, "PUT", {
        body: LearnerDetails,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.status == 200) {
            console.log("response", response);
          } else if (response.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch(function (error) {
          Sentry.captureException(error);
          console.log(error);
        });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement
        id="link-authentication-element"
        options={LinkAuthenticationElementOptions}
        onChange={(e) => setEmail(e.value.email)}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Typography
        sx={{ display: "flex", alignItems: "center" }}
        variant="h6"
        textAlign="left"
        m={2}
      >
        <span style={{ color: "red", fontSize: "small", paddingRight: "5px" }}>*</span>
        {t("headings.billingAddress")}
        <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>Required</span>
      </Typography>
      <AddressElement id="adress-element" options={AddressElementOptions} />
      <MDBox px={2} py={2} display="flex" justifyContent="end">
        <MDButton
          disabled={isLoading || !stripe || !elements}
          type="submit"
          id="submit"
          style={{
            color: "white",
            background: "#0AB2D6",
            marginTop: "15px",
          }}
        >
          <span id="button-text">
            {isLoading ? t("buttonTitles.Processing") : t("buttonTitles.payNow")}
          </span>
        </MDButton>
      </MDBox>

      {/* Show any error or success messages */}
      {message && (
        <div
          style={{
            color: "rgb(105, 115, 134)",
            fontSize: "16px",
            lineHeight: "20px",
            paddingTop: "12px",
            textAlign: "center",
          }}
          id="payment-message"
        >
          {message}
        </div>
      )}
    </form>
  );
}

CheckoutForm.defaultProps = {
  childDetails: {},
  handleClosePaymentModal: () => {},
  couponUsed: null,
};

CheckoutForm.propTypes = {
  childDetails: PropTypes.object,
  handleClosePaymentModal: PropTypes.func,
  couponUsed: PropTypes.any,
};
